import "./index.css";
import MainForm from "./Component/MainForm";

function App() {
    return (
        <>
            <MainForm />
        </>
    );
}

export default App;
